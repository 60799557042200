const wowhaus = {
  addBlogTeaserToNav: () => {
    const blogList = document.createElement('ul');
    blogList.classList.add('level_2', 'level_2__blog');
    const newsTeaser = document.querySelectorAll('.layout_latest');
    newsTeaser.forEach((item) => {
      const listItem = document.createElement('li');
      listItem.append(item.cloneNode(true));
      blogList.append(listItem);
    });
    const blogMenuItem = document.querySelector('.nav-main > ul > li.last');
    blogMenuItem.append(blogList);
  },
  augmentQuantityButtons: () => {
    $('.quantity__button').on('click', function () {
      const $button = $(this);
      const oldValue =
        $button.parent().find('input').val() || $button.parent().find('input').attr('placeholder');
      let newVal = null;
      if ($button.text() === '+') {
        newVal = parseFloat(oldValue) + 1;
      }

      if ($button.text() === '-') {
        if (oldValue > 0) {
          newVal = parseFloat(oldValue) - 1;
        } else {
          newVal = 0;
        }
      }

      $button.parent().find('input').val(newVal);
    });
  },
  calculateSubmenuWidth: () => {
    const nav = document.querySelector('.nav-main');
    const menuItems = nav.querySelectorAll('.level_1 > li');
    const navRect = nav.getBoundingClientRect();

    menuItems.forEach((item) => {
      item.addEventListener('mouseover', () => {
        const itemRect = item.getBoundingClientRect();
        const sub = item.querySelector('ul');
        if (item.classList.contains('page-blog')) {
          sub.style.left = 0;
          sub.style.width = `${navRect.width}px`;
        } else {
          sub.style.width = `${navRect.width - itemRect.x + navRect.x}px`;
        }
      });
    });
  },
  shadePageWhileNavigating() {
    const contentContainer = document.getElementById('container');
    const socialContainer = document.getElementById('page-aftercontent');
    const footerContainer = document.getElementById('footer');
    const mainNav = document.querySelector('.nav-main');

    mainNav.addEventListener('mouseover', () => {
      contentContainer.classList.add('shaded');
      socialContainer.classList.add('shaded');
      footerContainer.classList.add('shaded');
      const zGallery = document.querySelector('.zoomContainer');
      if (zGallery) {
        zGallery.classList.add('no-events');
      }
    });

    mainNav.addEventListener('mouseout', () => {
      contentContainer.classList.remove('shaded');
      socialContainer.classList.remove('shaded');
      footerContainer.classList.remove('shaded');
      const zGallery = document.querySelector('.zoomContainer');
      if (zGallery) {
        zGallery.classList.remove('no-events');
      }
    });
  },
  formatFormsWithErrors() {
    if (document.querySelectorAll('.widget .error').length) {
      document.querySelectorAll('.widget p.error').forEach((elem) => {
        elem.nextElementSibling.placeholder = elem.innerHTML;
        elem.style.display = 'none';
      });
    }
  },
};

const init = () => {
  document.querySelector('.hamburger').addEventListener('click', function () {
    this.classList.toggle('is-active');
  });

  wowhaus.addBlogTeaserToNav();
  wowhaus.augmentQuantityButtons();
  wowhaus.calculateSubmenuWidth();
  wowhaus.shadePageWhileNavigating();
  wowhaus.formatFormsWithErrors();
};

if (
  document.readyState === 'complete' ||
  (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
